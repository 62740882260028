footer {
  background-color: $footer-bg;
  color: $footer-color;
  font-size: 0.85rem;
  padding: 5rem 0;
  .logo {
    max-width: $logo-footer-max-width;
  }
  .social {
    a {
      color: $footer-color-social;
      margin: 0 .5rem .5rem .5rem;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      i {
        margin-right: 0;
      }
    }
  }
  a {
    color: $footer-color-a;
    &:hover {
      color: $footer-color-a-hover;
    }
  }
}
