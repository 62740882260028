h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-heading-sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow-wrap: initial;
  word-wrap: initial;
}

// Page title: loop
@for $i from 1 through $page-title-items {
  .page-title-#{$i} {
    $size: ($page-title-items + 1) - $i;
    $size-rem: $size + rem;
    margin-bottom: $size/2 + rem;
    // @include rfs(($size)rem);
    @if $size > 2 {
      font-weight: $page-title-font-weight;
    } @else {
      font-weight: $page-title-font-weight + 200;
    }
  
    b,
    strong {
      font-weight: $page-title-b-font-weight;
    }
  
    &.page-title-icon {
      display: flex;
      align-items: flex-end;
      i {
        // @include rfs(($size)rem);
        margin-bottom: $size + px;
        margin-right: 1rem;
      }
    }
  }
}

.mdi-5x,
.page-title,
.page-title-1 {
  @include rfs(4.5rem);
  &.page-title-icon {
    i {
      @include rfs(5rem);
    }
  }
}

.page-title-2 {
  @include rfs(4rem);
  &.page-title-icon {
    i {
      @include rfs(4rem);
    }
  }
}

.page-title-3 {
  @include rfs(3rem);
  &.page-title-icon {
    i {
      @include rfs(3rem);
    }
  }
  @include media-breakpoint-down(lg) {
    @include rfs(2rem);
  }
}

.page-title-4 {
  @include rfs(2rem);
  &.page-title-icon {
    i {
      @include rfs(2rem);
    }
  }
}

.page-title-5 {
  @include rfs(1rem);
  &.page-title-icon {
    i {
      @include rfs(1rem);
    }
  }
}

