.navbar {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.75s ease;
  background: $navbar-bg;
  color: color-yiq($navbar-bg);
  position: sticky;
  /*
  @supports (backdrop-filter: blur(5px)) {
    background-color: rgba($navbar-bg, .7) !important;
    backdrop-filter: blur(10px);
  } */
  // Container
  .navbar-container {
    width: 100%;
    display: flex;
  }
  // Scroll
  &.navbar-scroll {
    position: sticky;
    padding: $navbar-padding-x;
    background: $navbar-scroll-bg;
    color: color-yiq($navbar-scroll-bg);
    box-shadow: $navbar-scroll-box-shadow;
    .nav-item {
      .nav-link {
        color: $navbar-scroll-a-color;
        &:focus,
        &:hover {
          box-shadow: 0 4px 0 0 $primary;
        }
        &.active {
          box-shadow: 0 4px 0 0 $primary;
        }
        &.active:hover {
          box-shadow: 0 8px 0 0 $primary;
        }
      }
    }
    .navbar-toggler {
      .icon-bar {
        background: $navbar-scroll-toggler;
      }
    }
    .navbar-navigation {
      .navbar-brand {
        width: $logo-scroll-width;
        height: $logo-scroll-height;
        background-image: $logo-scroll-file;
      }
    }
  }
  // Navigation
  .navbar-navigation {
    display: flex;
    align-items: center;
    .navbar-brand {
      display: block;
      padding: 0;
      margin-right: $navbar-padding-x;
      font-size: 0;
      width: $logo-width;
      height: $logo-height;
      background-image: $logo-file;
      background-repeat: no-repeat;
      transition: $logo-transition;
      h1 {
        opacity: 0;
        font-size: 0;
      }
    }
    // Toggler
    .navbar-toggler {
      outline: 0 !important;
      .icon-bar {
        display: block;
        width: 22px;
        height: 2.5px;
        margin: 6px 0;
        background: $navbar-toggler;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
      }
      .icon-bar:nth-of-type(1) {
        transform: scale(1.2) rotate(45deg) translate(5px, 5px)
      }
      .icon-bar:nth-of-type(2) {
        opacity: 0;
      }
      .icon-bar:nth-of-type(3) {
        transform: scale(1.2) rotate(-45deg) translate(5px, -5px);
      }
      // Collapsed
      &.collapsed {
        .icon-bar {
          margin: 6px 0;
        }
        .icon-bar:nth-of-type(1) {
          -webkit-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
        }
        .icon-bar:nth-of-type(2) {
          opacity: 1;
          filter: alpha(opacity=100);
        }
        .icon-bar:nth-of-type(3) {
          -webkit-transform: rotate(0);
          -ms-transform: rotate(0);
          transform: rotate(0);
        }
      }
    }
    // Responsive
    @include media-breakpoint-down(sm) {
      width: 100%;
      justify-content: space-between;
      .navbar-brand {
        margin-right: 0;
      }
      .btn-icon {
        i {
          margin-right: 0;
        }
        span {
          display: none;
        }
      }
    }
  }
  // Item
  .nav-item {
    @include rfs($navbar-item-font-size);
    font-family: $navbar-item-font-family;
    font-weight: $navbar-item-font-weight;
    margin: 0 $navbar-padding-x/2;
    @include media-breakpoint-down(sm) {
      margin: $navbar-padding-y/2 0;
    }
    .btn {
      @include rfs($navbar-item-font-size);
      font-family: $navbar-item-font-family;
      font-weight: $navbar-item-font-weight;
    }
    .nav-link {
      color: $navbar-a-color;
      transition: 0.35s all ease;
      padding-right: 0 !important;
      padding-left: 0 !important;
      display: flex;
      align-items: center;

      &:focus,
      &:hover {
        box-shadow: 0 4px 0 0 $light;
      }
      &.active {
        box-shadow: 0 4px 0 0 $primary;
      }
      &.active:hover {
        box-shadow: 0 8px 0 0 $primary;
      }
    }
  }
  // Collapse
  .navbar-collapse {
    .navbar-nav {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

// Navbar: Secondary
.navbar-secondary {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .nav-right {
    .nav-link {
      display: flex;
      align-items: center;
    }
  }
}
