/*!
 * Inbright v3.0
 * Derian Castillo | hola@derianandre.com
 * Portafolio: http://derianandre.com/
 * Last revision: Derian Castillo
 * Time and date: 2:00 p.m. - 07/06/2019
 */

// YIQ
@import "sass-yiq/src/sass/yiq";

// Global variable overrides
@import "./variables";

// Andréstrap
@import "../../../scss/andrestrap";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Lora');

// Project style
@import "./style";