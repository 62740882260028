/*!
 * Andrestrap v1.5.1 (http://derianandre.com/)
 * No Copyright. Derian Castillo | hola@derianandre.com
 * Time and date: 11:00 a.m. - 22/05/2019
 */

// Global variables overrides
@import "./variables";

// Bootstrap
@import "bootstrap/scss/bootstrap";

// André's Heart a.k.a Core
@import "core";
