// Logo
$logo-file:                         url('../brand/logo.svg');
$logo-height:                       45px;
$logo-width:                        160px;
$logo-transition:                   all 0.45s ease-in-out;
// Logo: footer
$logo-footer-max-width:             150px;
// Logo: On-Scroll
$logo-scroll-file:                  url('../brand/logo.svg');
$logo-scroll-height:                45px;
$logo-scroll-width:                 160px;

// Icon
$icon-file:                         url('../brand/isotipo.svg');
$icon-width:                        45px;
$icon-height:                       45px;

// Colors
$primary:                         #3466AF;
$secondary:                       #F39322;

// Global
$global-bg:                         #FFF;
$global-color:                      #000;

// Body
$body-bg:                           #FFF;

// Footer
$footer-bg:                         #FFF;

// Fonts
$font-family-sans-serif:            "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-heading-sans-serif:    "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-heading-serif:         "Lora", Georgia, "Times New Roman", Times, serif;

// Navbar
$navbar-bg:                         #FFF;
$navbar-padding-y:                  1rem;
$navbar-padding-x:                  1rem;
$navbar-color:                     #000;
$navbar-expand:                     md;
// Navbar: Item (Links and buttons)
$navbar-item-font-family:           $font-family-heading-sans-serif;
$navbar-item-font-size:             1rem;
$navbar-item-font-weight:           500;
// Navbar: On-Scroll
$navbar-scroll-bg:                #FFF;
$navbar-scroll-box-shadow:          0 0 0 0;
$navbar-scroll-color:               yiq-contrast-color($navbar-scroll-bg);

// Grid
$grid-gutter-width:                 2rem;

// Card
$card-spacer-x:                     ($grid-gutter-width - 0.5rem);
$card-spacer-y:                     $grid-gutter-width/2;
$card-img-overlay-padding:          $grid-gutter-width;
$card-columns-gap:                  $grid-gutter-width;

// List group
$list-group-item-padding-y:         $grid-gutter-width/2;
$list-group-item-padding-x:         $card-spacer-x;

// Pagination
$pagination-bg-dark:              #FFF;
$pagination-border-color-dark:    #FFF;

// Carousel
$carousel-control-color:            #000;

$divisions-border-radius: 5rem;