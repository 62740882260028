#google_translate_element a {
  text-decoration: none !important;
}

.goog-te-gadget-simple  {
  background-color: rgba(255, 255, 255, 0.0) !important;
  border: 0px solid rgba(255, 255, 255, 0.0) !important;
  border-radius: 4px!important;
  font-size: 1rem!important;
  line-height:2rem!important;
  display: inline-block;
  cursor: pointer;
zoom: 1;
}

.goog-te-menu2 {
  max-width: 100%;
}

.goog-te-menu-value {
  color: #fff !important;
    &:before {
    font-family: 'Material Icons';
    content: "\E927";
    font-size: 2rem;
    vertical-align: -10px;
  }
  span, img {
    display: none;
  }
  span:nth-child(5) {
    display:none;
  }
  span:nth-child(3) {
    border:none!important;
    font-family: 'Material Icons';
    &:after {
      font-family: 'Material Icons';
      content: "\E5C5";
      font-size: 1.5rem;
      vertical-align: -6px;
    }
  }
}

.goog-te-gadget-icon {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important
}
