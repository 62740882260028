.page-404 {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $page-404-bg;
  color: $page-404-color;
  position: relative;
  overflow: hidden;
  &:before {
    content: "404";
    color: rgba($white, .025);
    font-size: 120vh;
    font-weight: 300;
    line-height: 0;
    position: absolute;
    bottom: 30vh;
    animation: page404before 5s infinite;
    z-index: -1;
  }
  .logo {
    display: block;
    width: $icon-width;
    height: $icon-height;
    background-image: $icon-file;
    background-repeat: no-repeat;
    background-position: center;
    animation: page404 1.5s infinite;
  }
  h1 {
    display: flex;
    align-items: center;
    font-size: 8rem;
    margin: 2rem 0;
    .material-icons {
      font-size: inherit;
      line-height: inherit;
    }
  }
}
