.navbar {
  .navbar-brand {
    @include media-breakpoint-down($navbar-expand) {
      transform: translateX(-1.25rem);
    }    
  }
}
/*
 * Index
 */
 .page-index {
   .navbar {
     position: fixed;
   }
  .main {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .carousel {
      position: absolute;
      left: 0;
      bottom: 0;
      top: 0;
      z-index: 10;
      padding: 1rem;
      max-width:80vw;
      mix-blend-mode: difference;
      @include media-breakpoint-down(lg) {
        max-width: 90vw;
      }
      
      @include media-breakpoint-down(md) {
        max-width: 100vw;
        .page-title {
          font-size: calc(1.8rem + 5vw) !important;
        }
      }

      .carousel-inner {
        display: flex;
        align-items: center;
        height: 100%;
        .carousel-item {
          opacity: 0;
          transition: transform 2s ease, opacity .5s ease-out;
          &.active {
            opacity: 1;
          }
        }
      }
      .page-title {
        font-weight: 300;
        display: inline;
        @include rfs(7rem);
        color: lighten($secondary, 15%);
        text-shadow: 5px 5px #333;
        strong {
          font-weight: 800;
          text-shadow: 10px 10px #333;
        }
      }
    }
    .img-main {
      position: relative;
      content: "";
      display: block;
      width: 100vw;
      height: 80vh;    
      @include media-breakpoint-down(md) {
        height: 65vh; 
      }  
      .img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background:
          linear-gradient(-90deg, transparent 79.1%, #FFF 0%),
          url('https://images.pexels.com/photos/373965/pexels-photo-373965.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260') right center no-repeat;
        background-size: cover;
        background-attachment: fixed;
        filter: grayscale(1);
      }
      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(-5deg, $primary 50%, lighten($secondary, 5%) 100%);
        mix-blend-mode: overlay;
        z-index: 1;
      }
    }
    footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparent;
    }
  }
  section {
    &.divisions {
      position: relative;
      color: #FFF;
      overflow: hidden;
      @include media-breakpoint-down(sm) {
        padding: 0 1rem;
      }
     .col {
        display: block;
        min-height: 60vh;
        position: relative;
        transition: all .35s ease;
        @media (min-width: 1600px) {
          margin: 3rem 0;
          &:first-child {
            border-radius: 0 $divisions-border-radius $divisions-border-radius 0;
            transform: perspective(600px) rotateY(15deg) !important;
            .wrapper {
              transform: perspective(600px) rotateY(5deg) !important;
            }
            &::after {
              border-radius: 0 $divisions-border-radius $divisions-border-radius 0;
            }
          }
          &:nth-child(2) {
            border-radius: $divisions-border-radius;
            transform: perspective(600px) rotateY(7.5deg) !important;
            .wrapper {
              transform: perspective(600px) rotateY(-5deg) !important;
            }
            &::after {
              border-radius: $divisions-border-radius;
            }
          }
          &:nth-child(3) {
            border-radius: $divisions-border-radius;
            transform: perspective(600px) rotateY(-7.5deg) !important;
            .wrapper {
              transform: perspective(600px) rotateY(5deg) !important;
            }
            &::after {
              border-radius: $divisions-border-radius;
            }
          }
          &:last-child {
            border-radius: $divisions-border-radius 0 0 $divisions-border-radius;
            transform: perspective(600px) rotateY(-15deg) !important;
            .wrapper {
              transform: perspective(600px) rotateY(5deg) !important;
            }
            &::after {
              border-radius: $divisions-border-radius 0 0 $divisions-border-radius;
            }
          }
        }
        @include media-breakpoint-down(xl) {
          min-height: 60vh;
        }
        @include media-breakpoint-only(lg) {
          min-height: 50vh;
          &::after {
            opacity: .65 !important;
          }
        }
        @include media-breakpoint-up(lg) {
          transform: none !important;
        }
        @include media-breakpoint-down(md) {
          p {
            padding: 0 !important;
          }
          .wrapper {
            padding: 4rem 1rem !important;
          }
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: 2rem;
          border-radius: $divisions-border-radius/2;
          &::after {
            border-radius: $divisions-border-radius/2;
          }
        }
        .wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          height: 100%;
          padding: 7.5rem 2rem 7.5rem;
        }
        &::after {
          content: ' ';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          transition: all .35s ease;
          z-index: -1;
          filter: grayscale(1);
          opacity: .15;
        }
        &:hover::after {
          opacity: .3;
          filter: blur(10px);
          mix-blend-mode: soft-light;
        }    
        &:hover {
          background-color: rgba($primary, .9);
        }
        &::after {
          background-size: cover;
          background-position: center;
          background-attachment: fixed;
        }
        h2 {
          font-size: 255%;
          text-shadow: 0 3px 0 rgba(0, 0, 0, .35);
        }
        h2:after {
          border-bottom: 10px solid $secondary;
          content: "";
          display: block;
          margin: 3rem auto 0;
          width: 100px;
        }
        p {
         margin: 3rem 0;
         padding: 0 3rem;
        }
      }
      .card-columns {
        column-count: 2;
      }
    }
  }
}

section {
  &.process {
    .investment {
      @include media-breakpoint-up(md) {
        border-right:  0.5px solid rgba($dark, .15);
        padding-right: 3rem;
      }
    }
    .methodology {
      @include media-breakpoint-up(md) {
        border-left:  0.5px solid rgba($dark, .15);
        padding-left: 3rem;
      }
    }
  }

  &.divisions {
    .xperts {
      background-color: rgba(42, 44, 48, .95);
      &::after {
        background-image: url("../img/divisions/xperts.jpg");
      }
    }
    .projects {
      background-color: rgba(54, 56, 60, .95);
      &::after {
        background-image: url("../img/divisions/projects.jpg");
      }
    }
    .ventures {
      background-color: rgba(66, 69, 73, .95);
      &::after {
        background-image: url("../img/divisions/ventures.jpg");
      }
    }
    .companies {
      background-color: rgba(78, 80, 84, .95);
      &::after {
        background-image: url("../img/divisions/companies.jpg");
      }
    }
  }

  /*
   * Clients
   */
  &.clients {
    .carousel {
      padding: 1rem 5rem;
      .carousel-control-prev,
      .carousel-control-next {
        width: 50px;
      }
      
      .carousel-item {
        background: #FFF;
        opacity: 0;
        transition: transform .15s ease, opacity .35s ease-out;
        &.active {
          opacity: 1;
        }
      }
    }
    .carousel-clients img {
      filter: brightness(0);
      margin: 0 auto;
    }
  }

  /*
   * Our Work
   */
  &.work {
    h3:after {
      border-bottom: 8px solid $secondary;
      content: "";
      display: block;
      margin: 1.5rem auto;
      width: 125px;
    }
    .box {
      margin: 2.5rem 0;
      img {
        margin-bottom: 2rem;
      }
    }
  }
}

.grid {
  display: grid;
  align-items: stretch;
  justify-items: center;
  grid-template-columns: repeat(6, 1fr);
  .post {
    display: flex;
    grid-column: span 6;
    grid-row: span 6;
    position: relative;
    text-decoration: none;
    color: #000;
    align-items: stretch;
    transition: all .35s ease;
    width: 100%;
    &::before {
      opacity: 0;
      visibility: hidden;
      transition: all .35s ease;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($primary, 1);
      mix-blend-mode: color;
      z-index: 3;
    }
    
    &:hover {
      color: #FFF;
      @include media-breakpoint-down(md) {
        .info {
          background: linear-gradient(0deg, rgba(0,0,0,0.925) 0%, rgba(0,0,0,0.6) 45%, rgba(0,0,0,0.1) 100%) !important;
        }
      }
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
    &:nth-child(2n) {
      &::before {
        background: rgba($secondary, 1);
      }
    }
    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
      grid-column: span 6 !important;
    }
    .info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 3rem;
      flex: 0 0 50%;
      max-width: 50%;
      h2 {
        margin-bottom: .5rem;
      }
      @include media-breakpoint-down(md) {
        padding: 2.5rem !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        color: #FFF;
        justify-content: flex-end;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        z-index: 3;
        background: linear-gradient(0deg, rgba(0,0,0,0.95) 0%, rgba(0,0,0,0.65) 35%, rgba(0,0,0,0.05) 100%) !important;
      }
      @include media-breakpoint-down(sm) {
        padding: 2rem !important;
        .page-title-4 {
          @include rfs(1.5rem, important);
        }
        .page-title-3 {
          @include rfs(2.25rem, important);
          font-weight: normal;
        }
      }
    }
    .image {
      padding: 0;
      flex: 0 0 50%;
      max-width: 50%;
      align-self: stretch;
      @include media-breakpoint-down(md) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
      img {
        width: 100%;
        height: 100% !important;
        object-fit: fill !important;
        @include media-breakpoint-down(md) {
          filter: grayscale(15%);
        }
        @include media-breakpoint-down(sm) {
          filter: grayscale(25%);
        }
      }
    }
    &:nth-child(2) {
      .info {
        order: 1;
        @include media-breakpoint-down(md) {
          order: initial;
        }
      }
    }
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      grid-column: span 3;
      grid-row: span 3;
      .info {
        padding: 1rem 1.5rem;
        @include media-breakpoint-up(xl) {
          .page-title-4 {
            @include rfs(1.5rem, important);
          }
          .page-title-3 {
            @include rfs(2rem, important);
          }          
        }
        @include media-breakpoint-between(lg,xl) {
          .page-title-4 {
            font-size: 1.15rem;
          }
          .page-title-3 {
            font-size: 1.75rem;
          }
        }
        @include media-breakpoint-between(md,lg) {
          .page-title-4 {
            font-size: 1rem;
          }
          .page-title-3 {
            font-size: 1.25rem;
          }
        }
        @include media-breakpoint-down(md) {
          .page-title-4 {
            font-size: 1.25rem;
          }
        }
      }
    }
    &:nth-child(1),
    &:nth-child(2) {
     .info {
       @include media-breakpoint-down(md) {
         .page-title-3 {
           @include rfs(3.5rem);
         }
       }
     } 
    }
    &:nth-child(3),
    &:nth-child(4) {
      .info {
        order: 1;
      }
    }    
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      grid-column: span 2;
      grid-row: span 2;
      .info {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 2rem 1rem;
        color: #FFF;
        justify-content: flex-end;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        z-index: 3;
        @include media-breakpoint-up(lg) {
          background: linear-gradient(0deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0.55) 35%, rgba(0,0,0,0.0) 100%);
        }
        .page-title-4 {
          font-size: 1.5rem;
        }
        .page-title-3 {
          font-size: 2rem;
        }
        @include media-breakpoint-between(md, lg) {
          .page-title-4 {
            font-size: 1rem;
          }
          .page-title-3 {
            font-size: 1.05rem;
            font-weight: normal;
          }
        }
      }
      .image {
        flex: 0 0 100%;
        max-width: 100%;        
      }
    }
  }
}

.page-post {
  .navbar {
    margin-bottom: 0 !important;
  }
}

.page-divisions {
  overflow-x: hidden;
  section {
    background: #FFF;
    .division {
      min-height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }
    .page-title {
      background: transparent;
      position: relative;
      text-align: center;
    }
    .post {
      padding: 0 2rem;
      transition: all .35s ease;
      text-align: center;
      .post-img {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        margin: 0 auto 1rem auto;
        transition: all .35s ease;
        box-shadow: inset 0 0 0 1px rgba($dark, .05);
      }
      &:hover {
        .post-img {
          box-shadow: inset 0 0 0 1px rgba($dark, .05), 0 1rem 2rem 0 rgba($dark, .2);
        }
        @include media-breakpoint-down(md) {
          transform: perspective(500px) rotateX(7.5deg) !important;
        }
      }
      &:first-child {
        &:hover {
          transform: perspective(500px) rotateX(7.5deg) rotateY(5deg);
        }
      }
      &:nth-child(2) {
        &:hover {
          transform: perspective(500px) rotateX(5deg) rotateY(2.5deg);
        }
      }
      &:nth-child(3) {
        &:hover {
          transform: perspective(500px) rotateX(5deg) rotateY(-2.5deg);
        }
      }
      &:last-child {
        &:hover {
          transform: perspective(500px) rotateX(7.5deg) rotateY(-5deg);
        }
      }
      a {
        color: #000;
        text-decoration: none;
      }
      small {
        display: block;
      }
    }
  }
}

article {
  .color-swatch {
    display: block;
    margin: 0.5rem 1rem;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    &.color-swatch-small {
      width: 30px;
      height: 30px;
      margin: 0.5rem;
    }
    &.bg-primary-old {
      background-color: #106bb2
    }
    &.bg-secondary-old {
      background-color: #f8ad00
    }
  }
  .serif,
  .sans-serif {
    font-size: 2.5rem;
  }
  .serif {
    font-weight: 500;
    font-family: "Lora",Georgia,"Times New Roman",Times,serif;
  }
  .sans-serif {
    font-weight: 700;
    font-family: "Montserrat",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  }
  @include rfs(1.15rem);
  .container {
    display: flex;
    flex-direction: column;
  }
  .date {
    text-align: right;
    font-size: .85rem;
    font-weight: 400;
    margin-bottom: 2rem;
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
  .category {
    letter-spacing: .5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: bolder;
    @include rfs(1.5rem);
  }
  .title {
    margin: 1rem 0 2rem;
    text-align: center;
    font-weight: lighter;
    font-family: $font-family-heading-sans-serif;
    @include rfs(3.75rem);
  }
  .img {
    align-self: center;
    margin: 2rem 2.5rem;
    position: relative;
    display: inline-block;
    width: auto;
    @include media-breakpoint-down(lg) {
      margin: 2rem 0rem !important;
    }
    img {
      max-width: 100%;
    }
    &::before {
      opacity: 0;
      visibility: hidden;
      transition: all .5s ease;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 1px 0px rgba($dark, .1), 0 .5rem 1rem .5rem rgba($dark, .15);
      transition: all .4s ease;
    }
    &:hover {
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .img-article {
    $imgWidthGutter: 100px;
    width: calc(100% + #{$imgWidthGutter});
    margin-left: 0 !important;
    margin-right: 0 !important;
    @include media-breakpoint-down(md) {
      width: 100%;
      transform: none;
    }
    img {
      width: 100%;
    }
  }
  .embed-responsive {
    max-width: 85%;
    margin: 4rem auto;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
  p, ol, ul {
    font-family: $font-family-heading-serif;
    line-height: 1.8rem;
    margin: 2rem auto;
    max-width: 700px;
    display: block;
  }
  blockquote {
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "“";
      color: $primary;
      font-weight: bolder;
      font-family: Georgia;
      font-size: 15rem;
      z-index: -1;
    }
    p {
      @include rfs(1.45rem);
      font-weight: 300;
      font-style: italic;
      padding-left: 2.5rem;
      color: rgba($dark, .75)
    }
    footer {
      font-size: 1rem;
      font-weight: 800;
    }
  }
}
.goog-te-menu-value {
  color: #000 !important;
}
footer {
  padding: 1rem 0;
}