.no-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

// Border: Radius
.br-100 {
  border-radius: 1000px;
}

.br-l-100 {
  border-radius: 1000px 0 0 1000px !important;
  padding-left: 1.5rem;
}

.br-r-100 {
  border-radius: 0 1000px 1000px 0 !important;
  padding-right: 1.5rem;
}

// Details
.color-detail {
  b {
    font-weight: 500;
    color: $primary;
  }
}

.cover-detail {
  b {
    color: $primary;
    font-weight: 900;
  }
  strong {
    border-bottom: 8px solid $primary;
    padding-bottom: .25rem;
  }
}

.heading-detail {
  b {
    font-weight: 900;
  }
   strong {
    font-weight: 900;
    border-bottom: 8px solid $primary;
    padding-bottom: .25rem;
  }
}

.title-detail {
  position: relative;
  &::after {
    border-bottom: 10px solid $primary;
    content: "";
    display: block;
    margin: 2rem auto 3rem;
    width: 120px;
  }
}

.bd-top::before,
.bd-bottom::after {
  content: "";
  display: block;
  width: 300px;
  margin: 2rem 0;
  border-bottom: 8px solid $primary;
}

// Icons
.icon-right {
  justify-content: flex-end;
  text-align: right;
  i {
    margin-left: $icon-margin-side;
    margin-right: 0;
  }
}

.nav-link-icon,
.btn-icon {
  i {
    margin-right: $icon-margin-side;
  }
  @include media-breakpoint-down(md) {
    i {
      margin-right: 0;
    }
    span {
      display: none;
    }
  }
  @include media-breakpoint-down(sm) {
    i {
      margin-right: $icon-margin-side;
    }
    span {
      display: block;
    }
  }
  &.btn-icon-block {
    i {
      margin-right: $icon-margin-side !important;
    }
    span {
      display: block !important;
    }
  }
}

// Button
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &.btn-rounded {
    border-radius: 1000px;
    padding: 0.5rem 1rem;
  }
  &.btn-special {
    border-width: 3px;
    border-radius: 1000px;
    font-family: $font-family-heading-sans-serif;
    font-weight: 500;
    padding: 0.75rem 1.5rem;
  }
}

.img-bg-before {
  position: relative;
  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    filter: contrast(150%) grayscale(100%);
    transition: 0.5s all ease;
    z-index: -1;
  }
}

// Ribbon
.ribbon {
  padding: 3rem 0;
  position: relative;
   h5 {
    margin: 0;
    font-weight: 300;
    font-size: 2rem;
  }

  &.ribbon-startup {
    background-color: rgba(42,44,48,.95);
      &::before {
      background-image: url("../../img/startup.jpg");
    }
  }
}

// Material Icons
.material-icons {
  vertical-align: middle;
  &.mdi-sm {
    font-size: 1.5rem;
  }
  &.mdi-md {
    font-size: 4rem;
  }
  &.mdi-lg {
    font-size: 5rem;
  }
}

// Accordion
.accordion {
  .list-group {
    a[aria-expanded="true"]{
      z-index: 2;
      color: color-yiq($primary);
      background-color: $primary;
      border-color: $primary;
    }
  }
}

// Card
.card {
  position: relative;
  border-radius: .25rem;
  box-shadow: 0 1px rgba(0,0,0,0.1);
  z-index: 2;
  .description {
    transition: all 0.3s ease;
    height: 0px;
    opacity: 0;
    font-size: 0;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: -1;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.3);
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    &::after {
      opacity: 1;
    }
    .description {
      margin-top: 1rem;
      font-size: .85rem;
    }
  }
  .card-heading {
    background: $primary;
    color: #FFF;
    border-radius: 0;
  }

  .product-img {
    width: 100%;
    max-height: 300px;
  }

  p {
    margin-bottom: 0;
  }

  &.card-icon {
    .icon {
      display: flex;
      align-items: center;
      justify-content: cneter;
      align-self: stretch;
      padding: $card-spacer-x;
      .material-icons {
        font-size: 3.5rem;
        color: #FFF;
      }
    }
    .display-6  {
      font-size: .8rem;
      margin-bottom: .25rem;
      text-transform: uppercase;
    }
    .display-5  {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      .description {
        display: block;
        opacity: 1;
        height: 80px;
      }
    }
  }
}

// Dropdown: Login
.dropdown-login {
  margin-top: 1rem;
  width: 250px;
}

// List
.list-icons {
  .icon {
    padding: .25rem 0;
    margin-right: 1rem;
  }
}

// Progress bar: Vertical
.progress-bar-vertical {
  width: 20px;
  min-height: 100px;
  display: flex;
  align-items: flex-end;
  float: left;

  .progress-bar {
    width: 100%;
    height: 0;
    -webkit-transition: height 1s ease;
    -o-transition: height 1s ease;
    transition: height 1s ease;
  }
}


// Custom labels
.label-profile-picture-es {
  &::after {
    content: "Elegir";
  }
}

// Custom select
.select-icon {
  position:relative;
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
  }  
  i {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 999;
    position: absolute;
    top: 1px;
    right: 1rem;
    bottom: 1px;
    margin: auto;
    pointer-events: none;
    background-color: $input-bg;
  }
}


// Link scroll
.link-scroll-wrapper {
  position: absolute;
  bottom: 2rem;
  .link-scroll {
    display: block;
    .mouse {
      max-width: $mouse-width;
      width: 100%;
      height: auto;
      animation-name: mouseMove;
      animation-duration: 3s;
      animation-timing-function: cubic-bezier(0.650, -0.550, 0.250, 1.500);
      animation-iteration-count: infinite;
      transform-origin: 50% 20.5px;
    }
    .scroll {
      animation-name: mouseScroll;
      animation-duration: 3s;
      animation-timing-function: cubic-bezier(0.650, -0.550, 0.250, 1.500);
      animation-iteration-count: infinite;
      transform-origin: 50% 20.5px;
      will-change: transform, opacity;
      opacity: 1;
    }
  }
}